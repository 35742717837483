<template>
    <div class="article-content">
        <el-scrollbar class="article-wrap">
            <div class="top-tab">
                <div class="btn-box">
                    <el-button type="primary" @click="goBack">返回</el-button>
                </div>
            </div>
            <div class="main-content">
                <div class="main-wrap">
                    <div class="top-title">{{articleInfo.title}}</div>
                    <div class="top-author">{{articleInfo.author}}</div>
                    <div class="top-content" v-html="articleInfo.text"></div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import {seeDrillStuDetail} from '@/utils/apis'

    export default {
        name: "articleDetail",
        data() {
            return {
                submit_id: Number(this.$route.query.id) || null,
                articleInfo: {},
            }
        },
        created() {
            if (this.submit_id !== null) {
                this.getEditList();
            }
        },
        methods: {
            getEditList() {
                let param = {
                    submit_id: this.submit_id
                }
                seeDrillStuDetail(param).then(res => {
                    this.articleInfo = res.data.data[0];
                }).catch(err => {
                    console.error(err)
                })
            },
            goBack() {
                this.$router.go(-1)
            },
        }
    }
</script>

<style scoped lang="scss">
    .article-content {
        position: fixed;
        top: 60px;
        bottom: 0;
        width: 100%;
        background: #F5F7F6;

        .article-wrap {
            height: 100%;
            display: flex;
            flex-direction: column;

            ::v-deep > .el-scrollbar__wrap {
                overflow-x: hidden;

                .el-scrollbar__view {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    margin: 20px;
                    background: #ffffff;
                }
            }
        }

        .top-tab {
            display: flex;
            justify-content: flex-end;
            border-bottom: 1px solid #F1F5FF;
            height: 40px;
            padding: 10px 0;

            .el-button {
                margin-right: 20px;
            }
        }

        .main-content {
            margin-top: 20px;
            flex: 1;
            height: 1%;

            .main-wrap {
                width: 900px;
                margin: 0 auto;
                height: 100%;

                .top-title {
                    text-align: center;
                    font-size: 20px;
                    color: #333333;
                }

                .top-author {
                    margin-top: 12px;
                    text-align: center;
                    font-size: 14px;
                    color: #666666;
                }

                .top-content {
                    margin-top: 20px;

                    ::v-deep p {
                        img {
                            max-width: 900px;
                        }
                    }
                }
            }
        }
    }
</style>